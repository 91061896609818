import styled from "styled-components";
import { Root } from "./CellRoot";
import { useAppContext } from "../../Contexts/AppContext";

const StyledRoot = styled(Root)`
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 5px;
`;

export const CellNumber = ({ index, mode }) => {
  const { selectedCell } = useAppContext();
  return (
    <StyledRoot
      style={{
        backgroundColor: selectedCell?.[mode] === index ? "blue" : undefined,
        color: selectedCell?.[mode] === index ? "#fff" : undefined,
        fontWeight: selectedCell?.[mode] === index ? 'bold' : undefined,
      }}
    >
      {index}
    </StyledRoot>
  );
};
