import React, { useCallback, useEffect, useState } from "react"; // Import useState from react

import styled from "styled-components";
import { Button, IconButton } from "@chakra-ui/react";
import { BsFillLightbulbFill, BsFillLightbulbOffFill } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { SettingsIcon } from "@chakra-ui/icons";
import { useAppContext } from "../../Contexts/AppContext";
import { Settings } from "../Settings/Settings";
import { useStopwatch } from "react-timer-hook";
import { useFormikContext } from "formik";

const ToolbarRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 20px;
`;

const FillRemaining = styled.span`
  flex: 1 1 auto;
`;

export const Toolbar = ({ settings, onSettingsChange, onStart }) => {
  const { isValid } = useFormikContext();
  const { hint, setHint } = useAppContext();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const {
    minutes,
    seconds,
    reset,
    pause,
    start: resume,
    isRunning,
  } = useStopwatch();

  const start = useCallback(() => {
    onStart();
    reset(undefined, true);
  }, [onStart, reset]);

  useEffect(() => {
    if (isValid) {
      pause();
    }
  }, [isValid, pause]);

  return (
    <>
      <ToolbarRoot>
        <IconButton
          variant={"ghost"}
          marginRight={2}
          icon={<SettingsIcon />}
          onClick={() => setSettingsOpen(true)}
        />
        {!settings.fill && (
          <>
            <Button type="button" onClick={start}>
              Start New
            </Button>

            <Button
              marginLeft={5}
              leftIcon={isRunning ? <FaPause /> : <FaPlay />}
              variant="solid"
              onClick={isRunning ? pause : resume}
            >
              {minutes}:{String(seconds).padStart(2, "0")}
            </Button>
          </>
        )}

        <FillRemaining />
        <IconButton
          variant={"ghost"}
          color={hint ? "orange" : undefined}
          icon={hint ? <BsFillLightbulbFill /> : <BsFillLightbulbOffFill />}
          onClick={() => setHint(!hint)}
        />
      </ToolbarRoot>
      <Settings
        values={{
          range: [settings.range[0], settings.range[1] - 1],
          fill: settings.fill,
        }}
        isOpen={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        onApply={(values) => {
          onSettingsChange(values);

          setSettingsOpen(false);
        }}
      />
    </>
  );
};
