import React, { useCallback, useMemo, useState, useEffect } from "react";
import Cell from "../Cell";
import range from "lodash/range";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { InfoPanel } from "../InfoPanel/InfoPanel";
import ConfettiExplosion from "react-confetti-explosion";
import { Toolbar } from "../Toolbar/Toolbar";
import { HStack, VStack } from "@chakra-ui/react";

const buildValidationSchema = (numbers) => {
  const schema = {};
  numbers.forEach((x) => {
    numbers.forEach((y) => {
      schema[`cell-${x}-${y}`] = yup
        .number()
        .required(`${x} x ${y} is required`)
        .oneOf([x * y]);
    });
  });
  return yup.object().shape(schema);
};

const randomBoolean = () => Math.random() >= 0.5;

const createGridData = (numbers, fillAll) => {
  const initialValues = {};
  let filledCount = 0;
  numbers.forEach((x) => {
    numbers.forEach((y) => {
      const fill = randomBoolean() || fillAll;
      initialValues[`cell-${x}-${y}`] = fill ? x * y : "";
      filledCount += fill ? 1 : 0;
    });
  });
  return { initialValues, filledCount };
};

export const Grid = () => {
  const [settings, setSettings] = useState({ range: [1, 11], fill: false });
  const numbers = useMemo(() => range(...settings.range), [settings.range]);
  const [{ initialValues, filledCount }, setGridData] = React.useState(
    createGridData(numbers, settings.fill)
  );

  const start = useCallback(() => {
    setGridData(createGridData(numbers, settings.fill));
  }, [numbers, settings.fill]);

  useEffect(() => {
    setGridData(createGridData(numbers, settings.fill));
  }, [settings.range, settings.fill, numbers]);

  const metadata = useMemo(() => {
    const totalCells = numbers.length * numbers.length;
    return {
      totalCells,
      filledCount,
      emptyCount: totalCells - filledCount,
    };
  }, [filledCount, numbers.length]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={buildValidationSchema(numbers)}
        validateOnMount
        enableReinitialize
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ dirty, isValid }) => (
          <Form style={{ width: "1080px" }}>
            <Toolbar
              onStart={start}
              settings={settings}
              onSettingsChange={(values) => {
                setSettings(values);
              }}
            />
            <HStack alignItems="flex-start">
              <VStack>
                {dirty && isValid && (
                  <ConfettiExplosion particleCount={1000} duration={10000} />
                )}
                <HStack>
                  <Cell.Root> X </Cell.Root>
                  {numbers.map((number) => (
                    <Cell.Number key={number} index={number} mode="x" />
                  ))}
                </HStack>
                {numbers.map((x) => (
                  <HStack key={x}>
                    <Cell.Number index={x} mode="y" />
                    {numbers.map((y) => (
                      <Cell.Edit key={`${x}x${y}`} x={y} y={x} />
                    ))}
                  </HStack>
                ))}
              </VStack>
              <InfoPanel metadata={metadata} />
            </HStack>
          </Form>
        )}
      </Formik>
    </>
  );
};
