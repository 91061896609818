import { createContext, useCallback, useState, useContext } from "react";

const AppContext = createContext({
  x: null,
  y: null,
  selectCell: (x, y, answer) => {},
  reset: () => {},
  hint: true,
  setHint: () => {},
});

export const AppProvider = ({ children }) => {
  const [selectedCell, setSelectedCell] = useState(null);
  const [hint, setHint] = useState(true);

  const selectCell = useCallback(
    (x, y) => {
      setSelectedCell({ x, y });
    },
    [setSelectedCell]
  );


  const reset = useCallback(() => {
    setSelectedCell(null);
  }, [setSelectedCell]);

  return (
    <AppContext.Provider
      value={{ selectedCell, selectCell, reset, hint, setHint }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
