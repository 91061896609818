import { EditableCell } from "./EditableCell";
import { CellNumber } from "./CellNumber";
import { Root } from "./CellRoot";

const cell = {
  Root,
  Number: CellNumber,
  Edit: EditableCell,
};

export default cell;
