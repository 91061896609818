import styled from "styled-components";
import { useAppContext } from "../../Contexts/AppContext";
import { useFormikContext, Field } from "formik";
import Cell from "../Cell";
import { StyledInput } from "../Cell/EditableCell";
import {
  Card,
  CardBody,
  Divider,
  Text,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { TbPlayerRecordFilled } from "react-icons/tb";
import { range } from "lodash";

const Root = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  width: 50%;
  padding: 20px;
`;

const Equation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoPanel = () => {
  const { selectedCell, hint } = useAppContext();
  const { touched, errors } = useFormikContext();

  const cellName = selectedCell
    ? `cell-${selectedCell.x}-${selectedCell.y}`
    : undefined;

  const renderRow = (x, key) => {
    return (
      <Grid key={key} templateColumns={`repeat(${x}, 1fr)`} gap={4}>
        {range(1, x + 1).map((index) => {
          return (
            <GridItem key={`${index}-${x}`} style={{ marginTop: 10 }}>
              <TbPlayerRecordFilled color="blue" />
            </GridItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <Root>
      {selectedCell && (
        <Equation>
          <h1 className="text-3xl">
            {selectedCell.x} x {selectedCell.y} ={" "}
          </h1>
          <Cell.Root>
            <Field
              name={cellName}
              as={StyledInput}
              style={{
                border:
                  touched[cellName] && errors[cellName]
                    ? "2px solid red"
                    : touched[cellName] && !errors[cellName]
                    ? "2px solid green"
                    : "1px solid black",
              }}
              type="number"
            />
          </Cell.Root>
        </Equation>
      )}
      {selectedCell && (
        <>
          <Card style={{ marginTop: 20 }}>
            <CardBody>
              <Text>
                Click on a cell to enter the product of the numbers in the cell
              </Text>
              {hint && (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <>
                    {range(1, selectedCell.y + 1).map((index) => {
                      return renderRow(selectedCell.x, index);
                    })}
                  </>
                </>
              )}
            </CardBody>
          </Card>
        </>
      )}
    </Root>
  );
};
