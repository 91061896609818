import { Field, useFormikContext } from "formik";
import { useMemo } from "react";
import styled from "styled-components";
import { Root } from "./CellRoot";
import { useAppContext } from "../../Contexts/AppContext";

export const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  border: ${({ error, valid }) =>
    error ? "1px solid red" : valid ? "1px solid green" : "1px solid black"};
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  padding: 0px;
  margin: 5px;
`;

export const EditableCell = ({ x, y }) => {
  const cellName = useMemo(() => `cell-${x}-${y}`, [x, y]);
  const { errors, touched, values } = useFormikContext();
  const { selectCell } = useAppContext();

  const cellValue = useMemo(() => values[cellName], [values, cellName]);

  return (
    <Root>
      <Field
        name={`cell-${x}-${y}`}
        as={StyledInput}
        style={{
          border:
            touched[cellName] && errors[cellName]
              ? "2px solid red"
              : touched[cellName] && !errors[cellName]
              ? "2px solid green"
              : "1px solid black",
        }}
        type="number"
        onFocus={(e) => selectCell(x, y, cellValue)}
      />
    </Root>
  );
};
