import styled from "styled-components";
import "./App.scss";
import { Grid } from "./Components/Grid/Grid";
import { AppProvider } from "./Contexts/AppContext";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 1280px;
  margin: 0 auto;
  `;
function App() {
  return (
    <Root>
      <AppProvider>
          <Grid />
      </AppProvider>
    </Root>
  );
}

export default App;
