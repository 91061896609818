import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  Checkbox,
  VStack,
} from "@chakra-ui/react";
export const Settings = ({ values, isOpen, onClose, onApply }) => {
  const [range, setRange] = React.useState(values?.range || [1, 10]);
  const [fill, setFill] = React.useState(values.fill);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack justifyItems='flex-start' alignItems='flex-start'>
            <Checkbox defaultChecked={fill} onChange={(e) => setFill(!fill)}>
              Fill all cells
            </Checkbox>
            <Text size={20}>Range</Text>
            <RangeSlider
              value={range}
              min={1}
              max={10}
              onChange={(value) => setRange(value)}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} boxSize={6}>
                {range[0]}
              </RangeSliderThumb>
              <RangeSliderThumb index={1} boxSize={6}>
                {range[1]}
              </RangeSliderThumb>
            </RangeSlider>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => onApply({ range, fill })}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
